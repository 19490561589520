import {ActionReducerMap} from '@ngrx/store';
import {FenceResultEntityState, fenceResultReducer} from '@app/store/faxe/fence-result/fence-result.reducer';
import {FenceEntityState, fenceReducer} from '@app/store/faxe/fences/fence.reducer';
import {ResultCodeEntityState, resultCodeReducer} from '@app/store/faxe/results-code/result-code.reducer';
import {
  RiderExtendedEntityState,
  riderExtendedReducer
} from '@app/store/faxe/rider-extended/rider-extended.reducer';
import {RaceClassEntityState, raceClassReducer} from '@app/store/faxe/race-class/race-class.reducer';
import {ClubLogoEntityState, clubLogoReducer} from '@app/store/faxe/club-logo/club-logo.reducer';
import {RiderEventEntityState, riderEventReducer} from '@app/store/faxe/rider-event/rider-event.reducer';

export interface FaxeStore {
  readonly riderExtended: RiderExtendedEntityState;
  readonly fenceResult: FenceResultEntityState;
  readonly fence: FenceEntityState;
  readonly resultCode: ResultCodeEntityState;
  readonly riderEvent: RiderEventEntityState;
  readonly raceClass: RaceClassEntityState;
  readonly clubLogo: ClubLogoEntityState;
}

export const faxeReducers: ActionReducerMap<FaxeStore> = {
  riderExtended: riderExtendedReducer,
  fenceResult: fenceResultReducer,
  fence: fenceReducer,
  resultCode: resultCodeReducer,
  riderEvent: riderEventReducer,
  raceClass: raceClassReducer,
  clubLogo: clubLogoReducer
};
