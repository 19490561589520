import {Injectable, NgZone} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {Store} from '@ngrx/store';
import {MultiMessageEnum} from '@app/services/multi-message-enum';
import {onSignalRStatus} from '@app/store/client-slices/signal-r/signal-r.actions';
import {RiderExtendedSignalR} from '@app/rest-client/faxe/models/rider-extended-signal-r';
import {addManyRider, removeAll} from '@app/store/faxe/rider-extended/rider-extended.actions';
import {AppConfigService} from '@app/utils/services/config/app-config.service';
import {MultiMessage} from '@assets/contract-faxe/multi-message';
import {isNotEmpty} from '@app/utils/functions/common-functions';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private readonly baseUrl: string = AppConfigService.environmentConfigData.faxeChatHub;
  private connection;

  constructor(private readonly store: Store,
              private readonly ngZone: NgZone) {
  }

  init() {

    console.log('Init signal r: ' + this.baseUrl);
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.baseUrl)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 60000) {
            console.log('retryContext elapsedMilliseconds < 60 ' + retryContext.elapsedMilliseconds);
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            const w1 = Math.random() * 10000;
            console.log('wait... ' + w1);
            return w1;
          }
          console.log('retryContext elapsedMilliseconds > 60 ' + retryContext.elapsedMilliseconds);
          const w2 = Math.random() * 20000;
          console.log('wait... ' + w2);
          return w2;
        }
      })
      .build();

    this.connection.onreconnected((connectionId) => {
      console.log('Signal R reconnected with connectionid ' + connectionId);
      this.ngZone.run(() => {
        this.connectionReastablished(connectionId, this.connection.state);
      });
    });

    this.connection.onreconnecting((error) => {
      console.log('Signal R error: ' + error);
      this.ngZone.run(() => {
        this.connectionReConnection(error?.name, error?.message, error?.stack, this.connection.state);
      });
    });

    this.connection.on('ReceivedMessage', (user, msg) => {
      console.log('Processing message outside ngZone...');
      const blob = new Blob([msg]);
      console.log('size ' +  blob.size + ' by8?');
      const multiMessage: MultiMessage = JSON.parse(msg);
      const data = JSON.parse(multiMessage?.data);
      if (multiMessage?.dataType && data) {
        this.handleMessageInNgZone(multiMessage.dataType, data);
      }
    });

    this.connection.start();

    this.ngZone.run(() => {
      this.store.dispatch(
        onSignalRStatus(
          {model: {status: '-', action: 'start'}}));
    });

  }

  handleMessageInNgZone(dataType: string, data: any) {
    this.ngZone.run(() => {
      console.log('received message. Now in ngZone', data);

      this.store.dispatch(
        onSignalRStatus(
          {model: {status: 'connected', action: 'message received'}}));

      switch (dataType) {
        case MultiMessageEnum.riderExtendedSignalR:
          this.resetStoreStartedRiderExtended(data);
          break;
        case MultiMessageEnum.riderEvent:
          break;
        case MultiMessageEnum.rider:
          break;
        case MultiMessageEnum.resultCode:
          break;
        case MultiMessageEnum.raceClass:
          break;
        case MultiMessageEnum.race:
          break;
        case MultiMessageEnum.fenceResult:
          break;
        case MultiMessageEnum.fence:
          break;
      }
    });
  }

  connectionReastablished(connectionId: string, status: string) {
    this.store.dispatch(
      onSignalRStatus(
        {model: {connectionId, status, action: 'connectionReastablished'}}));
  }

  connectionReConnection(errorName: string, errorMessage: string, errorStack: string, status: string) {
    this.store.dispatch(
      onSignalRStatus(
        {model: {errorName, errorMessage, errorStack, status, action: 'reconnecting'}}));
  }

  resetStoreStartedRiderExtended(riders: RiderExtendedSignalR[]) {
    this.store.dispatch(
      removeAll(
        {issuerStack: ['SignalRService']}));

    if (isNotEmpty(riders)) {
      this.store.dispatch(
        addManyRider(
          {models: riders, issuerStack: ['SignalRService']}));
    }
  }

}
