import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {FaxeStore} from '@app/store/faxe/faxe-store-model';
import {FenceResultEntityState} from '@app/store/faxe/fence-result/fence-result.reducer';
import {FenceEntityState} from '@app/store/faxe/fences/fence.reducer';
import {ResultCodeEntityState} from '@app/store/faxe/results-code/result-code.reducer';
import {RiderExtendedEntityState} from '@app/store/faxe/rider-extended/rider-extended.reducer';
import {RaceClassEntityState} from '@app/store/faxe/race-class/race-class.reducer';
import {ClubLogoEntityState} from '@app/store/faxe/club-logo/club-logo.reducer';
import {RiderEventEntityState} from '@app/store/faxe/rider-event/rider-event.reducer';



export const getFaxeState: MemoizedSelector<
  // eslint-disable-next-line @typescript-eslint/ban-types
  object,
  FaxeStore
  > = createFeatureSelector<FaxeStore>('faxe');

export const getRiderExtendedState: MemoizedSelector<
  // eslint-disable-next-line @typescript-eslint/ban-types
  object,
  RiderExtendedEntityState
  > = createSelector(getFaxeState, (state) => state.riderExtended);

export const getFenceResultState: MemoizedSelector<
  // eslint-disable-next-line @typescript-eslint/ban-types
  object,
  FenceResultEntityState
  > = createSelector(getFaxeState, (state) => state.fenceResult);

export const getFenceState: MemoizedSelector<
  // eslint-disable-next-line @typescript-eslint/ban-types
  object,
  FenceEntityState
  > = createSelector(getFaxeState, (state) => state.fence);

export const getResultCodesState: MemoizedSelector<
  // eslint-disable-next-line @typescript-eslint/ban-types
  object,
  ResultCodeEntityState
  > = createSelector(getFaxeState, (state) => state.resultCode);

export const geRiderEventsState: MemoizedSelector<
  // eslint-disable-next-line @typescript-eslint/ban-types
  object,
  RiderEventEntityState
  > = createSelector(getFaxeState, (state) => state.riderEvent);

export const getRaceClassState: MemoizedSelector<
  // eslint-disable-next-line @typescript-eslint/ban-types
  object,
  RaceClassEntityState
  > = createSelector(getFaxeState, (state) => state.raceClass);

export const getClubLogoState: MemoizedSelector<
  // eslint-disable-next-line @typescript-eslint/ban-types
  object,
  ClubLogoEntityState
  > = createSelector(getFaxeState, (state) => state.clubLogo);
